import {
  Typography, AuthLayout,
  useAlert,
} from 'common/designSystem';
import { ResetFrom } from 'features/account/reset-password/resetFrom';
import { SubmitHandler } from 'react-hook-form';
import lang from 'common/lang';
import { useState } from 'react';
import { ResetFromContainer, ResetPasswordContainer } from './styles';
import { useUpdatePasswordMutation } from './settingService';
import { UpdatePasswordDTO } from './types';

const { resetPassword: resetPasswordCopy } = lang;

export const AdminPage = () => {
  const [clearFields, setClearFields] = useState(false);
  const onSubmit: SubmitHandler<UpdatePasswordDTO> = (data) => {
    const payload: UpdatePasswordDTO = { ...data };
    setAlertState({
      type: 'success',
      title: '',
      content: '',
      show: false,
    });
    resetPassword(payload)
      .unwrap()
      .then(() => {
        setAlertState({
          type: 'success',
          title: '',
          content: resetPasswordCopy.success,
          show: true,
        });
        setClearFields(true);
      })
      .catch((error) => {
        console.error(error);
        setAlertState({
          type: 'error',
          title: '',
          content: error?.data?.message || resetPasswordCopy.error,
          show: true,
        });
      });
  };

  const [resetPassword, { isLoading }] = useUpdatePasswordMutation();
  const { setAlertState, Alert } = useAlert();
  return (
    <AuthLayout>
      <ResetPasswordContainer>
        <Typography variant="heading-05">Reset Password</Typography>
        <ResetFromContainer className="form-container">
          <ResetFrom onSubmit={onSubmit} Alert={Alert} shouldClear={clearFields} />
        </ResetFromContainer>
      </ResetPasswordContainer>
    </AuthLayout>
  );
};
export default AdminPage;
