/* eslint-disable no-shadow */
import { EmployeeDetails } from 'features/gifting/types';
import { OrderType } from 'features/home/types';
export type HandleData = {
    id: number;
    handle: string;
    batchQuantity: number;
};
export enum BillingStatus {
    error = 'error',
    completed = 'completed',
}
export type BillingAttempt = {
    subscriptionId: number,
    date: string;
    deliveryDate: string;
    status: BillingStatus | null;
};
export type CorporateSubscriptionPayment = {
    id: number | null,
    corporateSubscriptionId: number | null,
    nextPaymentDate: string | null,
    numberOfTreats: number | null,
    status: string | null,
    invoiceUrl: string | null,
    createdAt: string | null,
    updatedAt: string | null,
};

export type CorporateSubscriptionFiles = {
    [key: string]: string;
};

export type SubscriptionLineItems = {
    id: number,
    subscriptionId: number,
    title: string,
    productTitle: string,
    quantity: number,
    price: number,
    finalAmount: number,
    brandGuidelines: string | null;
    deliveryDate: string;
    postcardDesign: string | null;
    postcardMessage: string | null;
    recipients: string | null;
    ribbonDetail: string | null;
    variant: string;
    variantId: number;
    logo: string | null,
    website: string | null,
    ribbonPersonalisation: string | null,
    customMessage: string | null,
    productDiscount: number,
    shopifyProduct: HandleData,
};
export type PlanType = {
    shippingAddress1: string | null;
    shippingAddress2: string | null;
    shippingCity: string | null;
    shippingCompany: string | null;
    shippingCountry: string;
    shippingFirstName: string;
    shippingLastName: string | null;
    shippingPhone: string | null;
    shippingPostcode: string | null;
    shippingVat: number;
    id: number;
    subscriptionNumber: number,
    editUrl: string,
    shopifyId: string | number | null,
    deliveryInterval: string,
    status: string,
    nextPaymentDate: string | null,
    nextDeliveryDate: string | null,
    createdAt: string,
    cancelledAt: string,
    pausedAt: string,
    subscriptionLineItems: Array<SubscriptionLineItems>,
    subscriptionBillingAttempts: Array<BillingAttempt>,
    orders: Array<OrderType>,
};

export type CgPlanType = {
    id: number;
    planType: string;
    planStart: string;
    productIdShopify: number;
    perBoxPrice: number;
    variant: string;
    postcardMessage: string | null;
    quantity: number;
    stripePriceId: string;
    userId: number;
    taxable: boolean;
    status: string;
    sku: string;
    createdAt: string;
    updatedAt: string;
    cancelledAt: string,
    pausedAt: string,
    productTitle: string;
    repeatEveryYear: boolean;
    corporateSubscriptionFiles: CorporateSubscriptionFiles;
    orders: Array<OrderType>,
    employeesCount:number,
    subscriptionLineItems: Array<SubscriptionLineItems>,
    corporateSubscriptionPayments: Array<CorporateSubscriptionPayment>,
    employees: Array<EmployeeDetails>
    newlyJoinedEmployees?: Array<EmployeeDetails>
    refetchCgPlans?: () => void;
}

export type PlansResponse = Array<PlanType>;
export type CgPlansResponse = Array<CgPlanType>;

export type PlansActions = {
    onPauseSelect?: () => void;
    onCancelSelect?: () => void;
    onResumeSelect?: () => void;
    closeAlert?: () => void;
    refetchCgPlanDetails?: () => void;
};

export enum PlanStatus {
    active = 'ACTIVE',
    paused = 'PAUSED',
    cancelled = 'CANCELLED',
    expired = 'EXPIRED',
}

export enum EmploymentStatusType {
    active = 'ACTIVE',
    inactive = 'INACTIVE',
}
