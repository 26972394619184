import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography, AuthLayout, Loader, useModal, useAlert,
} from 'common/designSystem';
import lang from 'common/lang';
import { ChevronRight } from 'common/designSystem/iconComponents';
import { OrdersList } from 'features/home/styles';
import { OrderType } from 'features/home/types';

import CgOrder from 'common/designSystem/cgOrder';
import CgSubscriptionDetail from 'common/designSystem/cgSubscriptionDetails';
import { internalLinks } from 'common/constants';
import { SubsciptionCancelModal } from 'common/designSystem/cgCancelModal';
import { useCgPlanDetails } from './useCgPlanDetails';
import {
  BreadCrumpContainer, NoOrdersBlock, SubscriptionAndOrdersContainer,
} from './styles';
import { useCancelCgPlanMutation } from '../plansService';

export const CgPlanDetails = () => {
  const authHeaderRef = useRef<HTMLHeadElement | null>(null);
  const plansHeaderRef = useRef<HTMLDivElement | null>(null);
  const { plans: plansCopy } = lang;
  const navigate = useNavigate();
  const { id } = useParams();
  const { Alert, forceClose, setAlertState } = useAlert();
  const { Modal: CancelModal, setModalOpen: setCancelModalOpen } = useModal();
  const [triggerCancel, { isLoading }] = useCancelCgPlanMutation();

  const cancelPlan = () => {
    triggerCancel({ id: id || '' }).unwrap()
      .then(() => {
        setAlertState({
          variant: 'floating',
          show: true,
          type: 'success',
          content: plansCopy.apiSuccess,
        });
        refetchCgPlanDetails?.();
      })
      .catch((error) => {
        setAlertState({
          show: true,
          type: 'error',
          title: '',
          variant: 'floating',
          content: error.data?.message || '',
        });
      });
  };

  const {
    cgPlanDetails, ordersList, isCgPlansLoading,
    statusLoading, refetchCgPlanDetails,
  } = useCgPlanDetails({ id: id || 0 });

  useEffect(() => {
    authHeaderRef.current = document.querySelector('.auth-header');
    window.addEventListener('scroll', handleOrderHeaderPosition);

    return (() => window.removeEventListener('scroll', handleOrderHeaderPosition));
  }, []);

  const handleOrderHeaderPosition = () => {
    if (authHeaderRef?.current && plansHeaderRef?.current) {
      if (authHeaderRef.current.classList.contains('header-visible')) {
        plansHeaderRef.current.classList.add('header-exists');
        return;
      }
      plansHeaderRef.current.classList.remove('header-exists');
    }
  };

  return (
    <AuthLayout>
      { (isCgPlansLoading || statusLoading || isLoading) && <Loader />}
      <SubscriptionAndOrdersContainer>
        <div className="subscription">
          <div className="subscription-button-container" ref={plansHeaderRef}>
            {cgPlanDetails && (
              <>
                <BreadCrumpContainer>
                  <Typography variant="heading-compact-02" className="my-plans-text" data-cy="link-my-plans" onClick={() => navigate(internalLinks.plans)}>{plansCopy.myPlans}</Typography>
                  <ChevronRight />
                  <Typography variant="heading-compact-02" className="plan-with-id">{`${plansCopy.plan} #${id}`}</Typography>
                </BreadCrumpContainer>
                <CgSubscriptionDetail
                  {...cgPlanDetails}
                  onCancelSelect={() => setCancelModalOpen(true)}
                  closeAlert={forceClose}
                  refetchCgPlanDetails={refetchCgPlanDetails}
                />
              </>
            )}
          </div>
        </div>
        {(ordersList && ordersList?.length > 0) ? (
          <div className="orders">
            <OrdersList>
              {ordersList.map((order: OrderType) => <CgOrder key={order.id} {...order} isSubscription={true} perBoxPrice={cgPlanDetails?.perBoxPrice} />)}
            </OrdersList>
          </div>
        ) : (
          <NoOrdersBlock>
            <Typography variant="body-compact-02">{plansCopy.noOrders}</Typography>
          </NoOrdersBlock>
        )}
      </SubscriptionAndOrdersContainer>
      <CancelModal>
        <SubsciptionCancelModal
          cancelPlan={cancelPlan}
          closeModal={() => setCancelModalOpen(false)}
        />
      </CancelModal>
      <Alert />
    </AuthLayout>
  );
};
export default CgPlanDetails;
