import styled from 'styled-components';
import { breakpoints } from 'common/theme/breakpoints';
export const ResetPasswordContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 1084px;
    jsutify-content: center;
    align-items: center;
    margin: auto;
    @media ${breakpoints.tablet} {
        margin-top: 82px;
    }
`;

export const ResetFromContainer = styled.div`
    align-self: center;
    background: ${({ theme }) => theme.palette.background['ui-background']};
    width: 100%;
    border-radius: 8px;
    padding: 32px;
    max-width: 80vw;
     @media ${breakpoints.tablet} {
        max-width: 468px;
       
    }
`;
