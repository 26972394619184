import { Prompt, PromptHeader } from 'common/styles';
import { ConfirmInfoBlock, SingleInfoBlock } from 'features/plans/cgPlanDetails/styles';
import lang from 'common/lang';
import useModal from '../modal';
import Typography from '../typography';
import { CircularCloseIcon, CloseIcon } from '../iconComponents';
import Button from '../button/button';
import { CgCancelModalProps } from './type';

const { plans: plansCopy } = lang;

export const SubsciptionCancelModal = ({ cancelPlan, closeModal }:CgCancelModalProps) => {
  return (

    <Prompt>
      <PromptHeader>
        <Typography variant="heading-03" data-cy="confirm-cancel">
          {plansCopy.confirmCancel}
        </Typography>
        <CloseIcon data-cy="close-icon" onClick={() => closeModal(false)} />
      </PromptHeader>
      <Typography variant="body-compact-02" data-cy="confirm-cancel-plan-modal-heading">
        {plansCopy.confirmCancelPlan}
      </Typography>
      <ConfirmInfoBlock>
        <SingleInfoBlock>
          <CircularCloseIcon />
          <Typography variant="body-compact-02" data-cy="confirm-cancel-plan-subtext-1">
            {plansCopy.confirmCancelPlanSubtext1}
          </Typography>
        </SingleInfoBlock>
        <SingleInfoBlock>
          <CircularCloseIcon />
          <Typography variant="body-compact-02" data-cy="confirm-cancel-plan-subtext-2">
            {plansCopy.confirmCancelPlanSubtext2}
          </Typography>
        </SingleInfoBlock>
      </ConfirmInfoBlock>

      <Typography variant="body-compact-02" data-cy="confirm-cancel-plan-subtext-3">
        {plansCopy.confirmCancelPlanSubtext3}
      </Typography>
      <div className="button-container">
        <Button
          label={plansCopy.notNow}
          variant="outlined"
          data-cy="button-not-now"
          onClick={() => closeModal(false)}
        />
        <Button
          label={plansCopy.cancelPlan}
          variant="outlined"
          className="contact-support-button"
          data-cy="button-cancel-plan"
          onClick={() => { closeModal(false); cancelPlan(); }}
        />
      </div>
    </Prompt>
  );
};
