import {
  updatePasswordUrl,
} from 'common/utils/network/endpoints';
import { baseApi } from 'common/store/baseApi';
import { convertObjectFieldNamesFromCamelCaseToSnakeCase } from 'common/utils/helpers';
import { UpdatePasswordDTO } from './types';

export const settingApi = baseApi
  .injectEndpoints({
    endpoints: (builder) => ({
      updatePassword: builder.mutation<void, UpdatePasswordDTO>({
        query: (formData) => ({
          url: updatePasswordUrl,
          method: 'PATCH',
          body: convertObjectFieldNamesFromCamelCaseToSnakeCase(formData),
        }),
      }),
    }),
  });

export const {
  useUpdatePasswordMutation,
} = settingApi;
