import { useEffect, useMemo, useRef } from 'react';
import lang from 'common/lang';
import { yupResolver } from '@hookform/resolvers/yup';
import { resetPasswordValidationSchema } from 'features/account/reset-password/validationSchema';
import { useForm } from 'react-hook-form';
import { ResetPasswordDTO } from 'features/account/types';
import { Button, ControlledInputText, PasswordValidation } from 'common/designSystem';
import { ResetFromProps } from './type';

const { resetPassword: resetPasswordCopy } = lang;
export const ResetFrom = ({ onSubmit, Alert, shouldClear = false }: ResetFromProps) => {
  const resolver = { resolver: yupResolver(resetPasswordValidationSchema) };
  const {
    handleSubmit,
    control,
    formState: { isValid },
    watch,
    reset,
  } = useForm<ResetPasswordDTO>({ ...resolver, mode: 'onBlur' });
  const password = watch('password');

  const validatorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const passwordElement = document.getElementById('password_field');
    passwordElement?.addEventListener('focusout', hideValidation);
    return () => {
      passwordElement?.removeEventListener('focusout', hideValidation);
    };
  }, []);

  useEffect(() => {
    reset({
      password: '',
      passwordConfirmation: '',
    });
  }, [shouldClear]);

  const hideValidation = () => {
    if (validatorRef.current) {
      validatorRef.current.classList.remove('show');
    }
  };

  const showValidation = () => {
    if (validatorRef.current) {
      validatorRef.current.classList.add('show');
    }
  };

  const passwordValidations = useMemo(() => {
    return <PasswordValidation passwordValue={password} onValidityChange={() => {}} />;
  }, [password]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <ControlledInputText
          id="password_field"
          name="password"
          control={control}
          type="password"
          labelText={resetPasswordCopy.newPassword}
          placeholder={resetPasswordCopy.newPasswordPlaceholder}
          onFocus={showValidation}
        />
        <div ref={validatorRef} className="password-validations">
          {passwordValidations}
        </div>
      </div>
      <ControlledInputText
        type="password"
        name="passwordConfirmation"
        control={control}
        labelText={resetPasswordCopy.confirmPassword}
        placeholder={resetPasswordCopy.confirmPasswordPlaceHolder}
      />
      <Alert />
      <Button label={resetPasswordCopy.resetPasswordCTA} type="submit" variant="primary" size="medium" disabled={!isValid} style={{ marginTop: '24px' }} />
    </form>
  );
};
