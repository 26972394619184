const exampleAdminPage = {
  title: 'Admin Page',
};

const exampleWebPage = {
  title: 'Cakedrop - Under Construction',
  signUp: 'Sign up',
  login: 'Log in',
};

const signUp = {
  header: 'Create an account',
  firstNamePlaceholder: 'First name',
  firstName: '*First name',
  lastName: 'Last name',
  email: '*Email address',
  emailPlaceholder: 'Email address',
  password: '*Create password',
  passwordPlaceholder: 'Password',
  confirmPassword: '*Confirm password',
  submitButtonLabel: 'Create account',
  iAgree: 'I agree to the',
  terms: 'Terms',
  acknowledge: 'and acknowledge the',
  privacyPolicy: 'Privacy Policy',
  accountExists: 'Already have an account?',
  login: 'Log in',
  errorSigningUp: 'Error signing up.',
  companyName: '*Company name',
  comapnyNamePlaceholder: 'Company name',
  phoneNumber: 'Phone number',
  contactNumber: 'Contact number',
  occasionsLabel: 'How would you like to use CakeDrop in your office?',
  teamSize: 'Team size',
  marketingConsent: 'I agree to receive more information from CakeDrop, understand that I may unsubscribe at any time.',
};

const signIn = {
  header: 'Log in',
  email: '*Email address',
  emailPlaceholder: 'Email address',
  password: '*Password',
  passwordPlaceholder: 'Password',
  submitButtonLabel: 'Log in',
  forgotPassword: 'Forgot password?',
  donthaveAccount: 'Don’t have an account?',
  signUp: 'Sign up',
  signInError: 'Please enter a valid email and password.',
  unverifiedMessage: 'Please verify your one time password to access the platform.',
  docTitle: 'CakeDrop - My Account',
};

const forgotPassword = {
  resetPassword: 'Reset Password',
  instructions: 'Enter your email address and we will send you instructions to reset your password',
  email: '*Email address',
  emailAddress: 'Email address',
  sendLink: 'Send link',
  noEmail: 'Didn’t get any email?',
  contactSupport: 'Contact support',
  errorResettingPassword: 'Error resetting password.',
  success: 'Thanks! Your forgot password email is on the way.',
};

const resetPassword = {
  header: 'Create new password',
  instruction: 'Enter your new password',
  newPassword: '*New password',
  newPasswordPlaceholder: 'New password',
  confirmPassword: '*Confirm password',
  confirmPasswordPlaceHolder: 'Confirm password',
  resetPasswordCTA: 'Reset password',
  success: 'Your password has been reset successfully.',
  error: 'Error resetting password.',
  login: 'Log in',
};

const auth = {
  emailFormat: 'Please enter a valid email address',
  emailRequired: 'The email field is required',
  passwordFormat: 'Please enter a valid password',
  fieldRequired: 'This field is required',
  firstNameRequired: 'The first name field is required',
  passwordRequired: 'The password field is required',
  confirmPasswordRequired: 'The confirm password field is required',
  passwordValidationCheck: 'Please make sure your passwords match.',
  companyNameRequired: 'The company name field is required',
  password: {
    lowercase: 'One lowercase character',
    uppercase: 'One uppercase character',
    number: 'One number',
    special: 'One special character',
    length: '8 characters minimum',
  },
  consent: 'You must agree to the Privacy Policy to proceed',
};

const header = {
  login: 'Log in',
  signup: 'Sign up',
  logout: 'Log out',
  home: 'Home',
  browseTreats: 'Browse Treats',
  automatedGifting: 'Automated Gifting',
  ourStory: 'Our Story',
  orders: 'Your orders',
  plans: 'Your plans',
  rewards: 'Rewards',
  myOrders: 'My orders',
  myPlans: 'My plans',
  rewards1: 'Rewards',
  settings: 'Settings',
};

const megaMenu = {
  cakedropPopularCollections: 'CakeDrop Popular Collections',
  ocassions: 'Occasions',
  brandedTreats: 'Branded Treats',
  letterboxTreats: 'Letterbox Treats',
  classicTreats: 'Classic Treats',
  seasonalTreats: 'Seasonal Treats',
  viewMoreCollection: 'View more collections',
  occasions: 'Occasions',
  ocaasionsList: ['Employee Birthdays', 'Work Anniversaries', 'Office Treat Days', 'Corporate Gifting', 'Corporate Events', 'Brand Activations', 'Seasonal Events', 'Awareness Days'],
};

const footer = {
  copyright: 'Copyright © %year% · CakeDrop Ltd · Registered in England & Wales 10295405 · VAT number 266384573',
  shop: 'Shop',
  contact: 'Contact',
  tandCs: 'Terms & Conditions',
  privacyPolicy: 'Privacy Policy',
};

const verify = {
  signupSuccess: 'Thanks for signing up!',
  verifyInstruction: 'Please verify your account by entering the confirmation code we sent to',
  placeholder: 'Verification code',
  verifyCTA: 'Verify & Proceed',
  noCode: 'Didn’t receive the code?',
  resendCode: 'Resend code',
  or: 'or',
  contactSupport: 'Contact support',
  errorResending: 'Failed to resend confirmation code.',
  successResending: 'A new code has been sent to your email.',
  errorVerifying: 'Failed to verify confirmation code.',
  wrongOtp: "This code isn’t correct. Please click 'Resend code' to request a new one.",
  verified: 'Email verified successfully',
  forceVerify: 'Please verify your email',
  forceVerifyInfo: 'You’re almost there! Verifying your email gives you access to the CakeDrop account. Please enter the code we sent to your email.',
};

const landingPage = {
  badgeText: "Triple winner of 'Supplier of the Year' at the Office Management Awards (2021-2023)",
  gotoText: 'The ‘go to’ for workplace treats',
  sweetenTheText: 'Sweeten the',
  customerText: 'customer',
  employeeText: 'employee',
  workplaceText: 'workplace',
  brandText: 'brand',
  experienceText: 'experience',
  detailText: 'Create impactful experiences with unique branded treats.',
  detailText1: 'Customise, schedule and automate with CakeDrop.',
  signUp: 'Sign up',
  findTreats: 'Find treats',
};

const home = {
  yourOrders: 'Your orders',
  treats: 'Time for some treats?',
  orderInfo: 'Order status and history will show up here once an order has been placed',
  browseTreats: 'Browse treats',
  contactQuestion: 'Have questions about your order?',
  contactSupport: 'Contact support',
  yourOrdersInfo: 'View status and history about your treat orders',
  acgHeading: 'Automate gifting for your employee milestones',
  acgSubText: 'Schedule your employee birthdays and work anniversaries with CakeDrop and we’ll ensure they get a gift delivered to their door on their special day.',
  getStarted: 'Get started',
  findOutMore: 'Find out more',
  myOrders: 'My orders',
};
const plans = {
  yourPlans: 'Your plans',
  delights: 'Time for some delights?',
  orderInfo: 'Order status and history will show up here once an order has been placed',
  shopTreats: 'Shop treats',
  contactQuestion: 'Have questions about your order?',
  contactSupport: 'Contact support',
  yourPlansInfo: 'Summary and options to help you view and manage your plans',
  yourPlansInfoEmpty: 'The summary of your treat plans will show up here once a subscription has been created',
  newRecipients: 'UK team members joined your company in the last 30 days.',
  subscribeText: 'Subscribe to recurring treats',
  automateGifting: 'Automate gifting for employee milestones',
  subscribeSubtext: 'Schedule a regular treat day for your team or subscribe to a recurring delivery of branded treats for your space.',
  automateGiftingSubtext: 'Schedule your employee birthdays and work anniversaries with CakeDrop and we’ll ensure they get a gift delivered to their door on their special day.',
  automateGiftingSubtext1: 'No upfront fees, no commitment, cancel anytime.',
  getStarted: 'Get started',
  findOutMore: 'Find out more',
  recurringOrderSubtext: 'Schedule a regular treat day for your team or subscribe to a recurring delivery of branded treats for your space.',
  browseTreats: 'Browse treats',
  getInTouch: 'Get in touch',
  pausePlan: 'Pause plan',
  cancelPlan: 'Cancel plan',
  managePlan: 'Manage plan',
  edit: 'Edit',
  confirmPause: 'Are you sure you want to pause your plan?',
  pauseInfo: 'Your plan will be paused and you won’t receive any future invoices or get charged automatically.',
  notNow: 'Not now',
  pauseIt: 'Pause it',
  confirmCancel: 'Are you sure you want to cancel your plan?',
  confirmInfo: 'Any future orders will be cancelled and you won’t receive any future invoices or get charged automatically.',
  subscribeAgain: 'Subscribe again',
  cancelledStaticAlert: 'This plan is cancelled on %date% and no billing attempts were made.',
  pausedStaticAlert: 'This plan is paused on %date% and no billing attempts were made.',
  apiSuccess: 'Your plan has been updated successfully.',
  resumePlan: 'Resume plan',
  intervalText: 'Repeats every',
  deliveryDate: 'Delivery date',
  nextPaymentDate: 'Next payment due date',
  nextDeliveryDate: 'Next delivery date',
  addToPlan: 'Add to plan',
  review: 'Review',
  viewPlanDetails: 'View plan details',
  cancelledOn: 'Cancelled on',
  pausedOn: 'Paused on',
  confirmResume: 'Are you sure you want to resume your plan?',
  resumeSubtext: 'Your plan will be resumed from today.',
  cancel: 'Cancel',
  resumeNow: 'Resume now',
  errorAlertText1: 'Oh no! The request didn’t go through, please try again or ',
  errorTextContactSupport: 'contact support ',
  errorAlertText2: ' for further assistance.',
  upcomingDeliveries: 'Upcoming deliveries',
  processingDates: 'Your billing schedule will be created within 10 minutes.',
  paymentDueDate: 'Payment due date',
  chooseTreat: 'Choose treat',
  scheduleTreats: 'Schedule treats for new occasions?',
  createRecurringOrder: 'Create a recurring order',
  createGiftingPlan: 'Create gifting plan',
  connected: 'Connnected',
  lastUpdated: 'Last updated',
  disconnect: 'Disconnect',
  disconnectHrIntegration: 'Disconnect the HR integration',
  pleaseContactForHrDisconnect: 'Please contact us to remove the integration for you.',
  recipients: 'recipients',
  paymentForecast: 'Payment forecast',
  chargesInfo: 'You will be charged on the 25th every month based on the number of treats delivered. The Platform fee will be included if applicable.',
  perMonthChargesInfo: 'Please note that we charge £20 per month for the use of CakeDrop’s automate gifting feature.',
  confirmCancelBirthdayPlan: 'Please contact support to cancel your plan. Please note: To cancel your subscription and avoid being charged for the upcoming month, you must cancel prior to the 15th of the current month.',
  plan: 'Plan',
  noOrders: 'Order status and history will show up here once an order has been placed.',
  breadcrumpbRecipents: 'Recipients',
  editRecipients: 'Edit recipients',
  updatePlan: 'Update plan',
  newMembers: 'new members',
  newMember: 'new member',
  myPlans: 'My plans',
  confirmCancelPlan: 'The plan will be cancelled immediately and you won’t receive the following:',
  confirmCancelPlanSubtext1: 'Milestone treats for employees',
  confirmCancelPlanSubtext2: 'Future invoices',
  confirmCancelPlanSubtext3: 'To avoid being charged for the upcoming month, you must cancel prior to the 20th of the current month.',
  returnToMyPlans: 'Return to my plans',
  connectionIssue: 'Connection issue',
  connectionIssueHeading: 'HR connection issue detected',
  connectionIssueSubtext: 'Our support team has been notified and we’ll keep you updated on the progress.',
};

const order = {
  orderNumber: 'Order number',
  link: 'Link',
  orderAgain: 'Order again',
  subtotal: 'Subtotal',
  shipping: 'Shipping',
  vat: 'VAT',
  total: 'Total',
  shippingAddress: 'Shipping address',
  viewInvoice: 'View invoice',
  placed: 'Placed',
  discount: 'Discount',
  logo: 'Logo:',
  website: 'Website:',
  brandGuidelines: 'Brand Guidelines:',
  ribbon: 'Ribbon:',
  postcardMessage: 'Postcard Message:',
  postcardDesign: 'Postcard Design:',
  multipleRecipients: 'Multiple Recipients:',
  deliveryDate: 'Delivery date:',
  multipleRecipientsShipping: 'This order has multiple recipients',
  subscriptionNumber: 'Subscription number',
  recurringTreats: 'Recurring Order',
  quantity: 'Quantity:',
  planNumber: 'Plan number',
  perBox: 'PER BOX',
  treats: 'treats',
};

const gifting = {
  giftingHeader: 'Automate gifting with CakeDrop',
  back: 'Back',
  next: 'Next',
  treat: 'Treat',
  plan: 'Plan',
  hrIntegration: 'HR Integration',
  recipients: 'Recipients',
  payment: 'Payment',
  treatHeading: 'Choose treat',
  planHeading: 'Add plan details and your brand',
  hrIntegrationHeading: 'Connect your HR Platform',
  recipientsHeading: 'Select recipients',
  paymentHeading: 'Payment details',
  subscribeNow: 'Subscribe now',
  perBox: 'PER BOX',
  discardGiftingPlan: 'Discard gifting plan',
  discardInfo: 'The progress won’t be saved once you discard the changes. Are you sure you want to leave this page?',
  stayOn: 'Stay on',
  discard: 'Discard',
  planType: 'Plan type',
  birthdayEmoji: '🥳',
  anniversaryEmoji: '🏆',
  birthdayPlan: 'Birthday Plan',
  anniversaryPlan: 'Anniversary Plan',
  workAnniversaryPlan: 'Work Anniversary Plan',
  birthdayPlanInfo: 'Your team members will receive the chosen treat on their birthdays.',
  anniversaryPlanInfo: 'Your team members will receive the chosen treat on their',
  existingPlan: 'You already have a plan with us. You can review the plan details from',
  yourPlans: 'Your Plans',
  page: 'page.',
  first: 'first',
  firstYear: 'First Year',
  everyYear: 'Every Year',
  workAnniversaries: 'work anniversaries.',
  defaultCustomMessageAnniversary: 'Happy work-iversary! You’ve been an invaluable part of our team. Here’s to another year of incredible work and accomplishments!',
  celebrationFrequencyPlan: ['Every year', 'First year'],
  CelebrationFrequency: 'Celebration frequency',
  anniversaryInfo: 'Want to schedule work anniversary gifts?',
  getEarlyAccess: 'Get early access',
  dietaryRequiements: 'Dietary Requirements',
  custom: 'CUSTOM',
  upload: 'upload',
  personalMessage: 'Personal message',
  personalisedPostcard: 'Personalised postcard',
  cakedropPostcard: 'CakeDrop postcard',
  postcardDesign: 'Postcard design',
  downloadTemplate: 'Download template',
  customPostcard: 'Custom Postcard',
  customPostcardDesign: 'Custom Postcard Design (optional)',
  customPostcardDesignText: 'Upload your artwork file below. The design must be A6 single-sided with crop marks & bleed and in pdf/jpg/png format.',
  personalMessageText: 'Each treat box includes a postcard featuring your personal message.',
  customMessageLabel: 'Include a message with your treat',
  allergenInfo: 'Allergies & Dietary Requirements',
  allergenInfo1: 'We are able to provide gluten free or vegan alternatives for individuals with these particular dietary requirements. If your HR system specifies this dietary information, we will automatically assign Vegan or Gluten Free alternatives to those team members where this information has been specified.',
  allergenInfo2: 'Allergen information is also included in each box. Kindly note that all of our products are made in a kitchen that handles wheat, dairy, eggs, soy & nuts.',
  allergenInfo3: 'Please note, the Candy Box cannot be made Vegan or Gluten Free.',
  planStart: 'Plan start',
  letterboxFriendly: 'Letterbox-friendly',
  pauseOrCancel: 'Pause or cancel anytime',
  defaultCustomMessage: 'Happy Birthday! Thanks for making work such a great place to be. I hope you have a wonderful day and a brilliant year ahead!',
  planSummary: 'Plan summary',
  addCustomMessage: "Personal message can't be empty",
  postcardMessage: 'Postcard message:',
  reloadConfirmation: 'Are you sure you want to leave this page? Your changes may not be saved.',
  connectHrPlatform: 'Connect your HR Platform',
  connectHrPlatformSubtext1: 'We\'ll retrieve employee names, addresses, birthdays, start date & dietary requirements',
  connectHrPlatformSubtext2: 'Get started below by selecting your HR system and then simply follow the instructions to connect and keep important dates in sync.',
  connectHrPlatformAlertTitle: 'Any changes will be updated automatically',
  connecHrPlatformAlertContent1: 'If you have new starters, leavers or employees that move home or change their dietary preferences, this information will be retrieved from your HR platform and updated in CakeDrop on a daily basis. You’ll be able to review your team members at any time from the',
  connectHrPlatformAlertContent2: 'Your Plans',
  connectHrPlatformAlertContent3: 'section of your account.',
  getStarted: 'Get started',
  continueWith: 'Continue with',
  recipientsLower: 'recipients of',
  singleRecipient: 'recipient of',
  of: 'of',
  employees: 'employees',
  giftingSearch: 'giftingSearch',
  searchByName: 'Search by name',
  name: 'Name',
  department: 'Department',
  birthday: 'Birthday',
  startDate: 'Start date',
  dietaryRequirements: 'Dietary Requirements',
  homeAddress: 'Home address',
  employeesLoading: 'Sync in progress, please wait, this may take a few minutes...',
  employeeLoadingForProgressiveLoader: 'The sync is in progress, please allow up to five minutes for the process to complete.',
  employeeLoadingAfterProgressiveLoader: 'It seems that the sync is taking longer than anticipated. Thank you for your patience.',
  employeeLoadingError1: 'Oh no, we encountered an issue syncing your HR platform.',
  noSearchResults: 'No employees found with that name, please try again.',
  employeeLoadingError: 'Please visit our contact page or chat with us in app for assistance getting the connection set up.',
  returnToMyPlans: 'Return to my plans',
  contactSupport: 'Contact support',
  selectAll: 'Select all',
  clearSelection: 'Clear selection',
  paymentForecast1: 'Based on the selected team members, the next payment for your Birthday treats will be',
  paymentForecast1ForWorkAnniversary: 'Based on the selected team members, the next payment for your Work Anniversary treats will be',
  paymentForecast2: 'with an annual cost of',
  paymentForecast3: 'at',
  paymentForecast4: 'per person.',
  planStartAndPaymentInfo: 'Your plan will start in %plan_start_month%, with your first payment taken on the 25th %plan_payment_month%.',
  platformFee: 'Platform fee',
  platformFeeNote: 'Please note that we charge £20 per month for the use of CakeDrop’s automate gifting feature.',
  totalRecipients: 'Total recipients',
  estimatedAnnualCost: 'Estimated annual cost',
  nextPaymentDue: 'Next payment due 25th',
  birthdayTreats: 'Birthday treats',
  anniversaryTreats: 'Work Anniversary treats',
  treats: 'treats',
  vat: 'VAT (20%)',
  totalDue: 'Total due',
  firstNameRequired: 'The first name field is required',
  fieldRequired: 'This field is required',
  invalidExpirationDate: 'Expiration date must be of the format MM/YYYY',
  invalidSecurityCode: 'Please enter a valid security code',
  invalidCard: 'Please enter a valid card number',
  cardNumber: 'Card number',
  nameOnCard: 'Name on card',
  expirationDate: 'Expiration date',
  expirationDatePlaceholder: 'MM/YYYY',
  securityCode: 'Security code',
  reference: 'Reference',
  referencePlaceholder: 'Optional',
  secureAndEncrypted: 'All transactions are secure and encrypted.',
  billingAddress: 'Billing address',
  billingAddressInfo: 'Provide the address that matches your card.',
  firstName: 'First name',
  lastName: 'Last name',
  company: 'Company',
  addressLine1: 'Address line 1',
  addressLine2: 'Address line 2',
  city: 'City',
  postcode: 'Postcode',
  phone: 'Phone',
  failedToCreatePlan: 'Failed to create plan!',
  planCreatedSuccess: 'Congrats! Your Birthday Plan has been set up!',
  anniversaryPlanCreatedSuccess: 'Congrats! Your Work Anniversary Plan has been set up!',
  planCreatedSuccessInfo: 'You can track the status of your orders and manage your plan easily within your CakeDrop account.',
  viewPlanDetails: 'View plan details',
  cardNumberErrorCode: 'incomplete_number',
  expiryErrorCode: 'incomplete_expiry',
  cvcErrorCode: 'incomplete_cvc',
  success: 'success',
  automatedEmployeeGifting: 'Automated Employee Gifting',
  noEmployeeFound: 'No employees found, please try another plan.',
  eligibleEmployeeModalLink: 'Can’t find some of the employees in the list?',
  eligibleEmployeeModalHeader: 'To whom can we deliver?',
  eligibleEmployeeModalText1: 'For all plans, employees with a home address listed in the UK within your HR system will be included.',
  eligibleEmployeeModalText2: 'Specifically for the first Work Anniversary Plan, only employees with a tenure of less than one year from their start date will be shown in the list.',
  eligibleEmployeeModalText3: 'If employees are still missing, please check their DOB, Start Date & Address is fully completed in your HR platform (including country).',
  gotIt: 'Got it',
  gdprCompliant: 'GDPR compliant',
  dataProtectionAtCakeDrop: 'Data protection at CakeDrop',
  dataProtectionModalHeader: 'How CakeDrop handles data?',
  dataProtectionModalText1: 'At CakeDrop we take data protection seriously and so only request the data we need. We securely integrate with your HR system to fetch employee names, dates of birth, home addresses, job titles, start dates, and dietary requirements (where possible). We do not store any other information and our process is fully GDPR compliant.',
  dataProtectionModalText2: 'Read more about CakeDrop’s ',
  dataProtectionModalText3: 'Data Policy',
  selectTreat: 'Select treat',
  selected: 'Selected',
  signUpHeader: 'Create an account to finish setting up your gifting plan',
};
const components = {
  characters: 'characters',
  uploadFile: 'Upload file',
  failedToUpload: 'Failed to upload file!',
  allowedFileTypes: 'Only PDF, PNG, JPG, and JPEG files are allowed',
  fileSizeLimit: 'File size too large. Please upload a PDF, PNG or JPEG file that is less than 5 MB.',
};
const bugsnag = {
  errorText: 'Bugsnag API key is empty. Bugsnag initialization skipped.',
  errorPluginNotAvalible: 'Bugsnag React plugin is not available',
};

export const lang = {
  exampleAdminPage,
  exampleWebPage,
  signUp,
  signIn,
  auth,
  forgotPassword,
  resetPassword,
  header,
  footer,
  verify,
  landingPage,
  home,
  order,
  plans,
  gifting,
  components,
  megaMenu,
  bugsnag,
};

export default lang;
