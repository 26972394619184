import lang from 'common/lang';
import { CgPlanType, PlanStatus } from 'features/plans/types';
import { formatPlanDates } from 'common/utils/helpers';
import { useNavigate } from 'react-router-dom';
import { internalLinks } from 'common/constants';
import { useCancelCgPlanMutation } from 'features/plans/plansService';
import Button from '../button/button';
import { RecipientsIcon } from '../iconComponents';
import {
  CgPlansContainer, Summary, CgViewPlanDetailsContainer, IntervalContainer,
  AlertContainer,
} from './styles';
import { CgSubscriptionHeader } from './cgSubscriptionHeader';
import { CgProductDetails } from './cgProductDetails';
import Typography from '../typography';
import useModal from '../modal';
import useAlert from '../alert/useAlert';
import Loader from '../loader';
import { SubsciptionCancelModal } from '../cgCancelModal';

export const CgSubscription = (props: CgPlanType) => {
  const {
    createdAt, id, status, employeesCount, refetchCgPlans,
  } = props;
  const { plans: plansCopy } = lang;
  const navigate = useNavigate();
  const [triggerCancel, { isLoading }] = useCancelCgPlanMutation();
  const { setAlertState, Alert } = useAlert();
  const { Modal: CancelModal, setModalOpen: setCancelModalOpen } = useModal();

  const cancelPlan = () => {
    triggerCancel({ id }).unwrap()
      .then(() => {
        setAlertState({
          show: true,
          type: 'success',
          content: plansCopy.apiSuccess,
        });
        refetchCgPlans?.();
      })
      .catch((error) => {
        setAlertState({
          show: true,
          type: 'error',
          title: '',
          content: error.data?.message || '',
        });
      });
  };

  return (
    <CgPlansContainer>
      { (isLoading) && <Loader />}
      <CgSubscriptionHeader
        name={id.toString()}
        createdAt={formatPlanDates(createdAt)}
      />
      <Summary>
        <CgProductDetails key={id} {...props} status={status} />
      </Summary>
      <IntervalContainer>
        <RecipientsIcon />
        <Typography variant="heading-compact-01" className="recipient-count" onClick={() => navigate(`${internalLinks.corporateGiftingPlan}${id}${internalLinks.recipients}`)} data-cy="recipients-link">
          {employeesCount}
          {' '}
          {plansCopy.recipients}
        </Typography>
      </IntervalContainer>

      <CgViewPlanDetailsContainer>
        <Button
          label={plansCopy.viewPlanDetails}
          variant="primary"
          size="base"
          data-cy="button-view-plan-details"
          onClick={() => navigate(`${internalLinks.corporateGiftingPlan}${id}`)}
        />
        { status === PlanStatus.active && (
          <Button
            label={plansCopy.cancel}
            variant="ghost"
            size="base"
            data-cy="button-view-plan-details"
            className="cancel-button"
            onClick={() => setCancelModalOpen(true)}
          />

        )}
      </CgViewPlanDetailsContainer>
      <CancelModal>
        <SubsciptionCancelModal
          cancelPlan={cancelPlan}
          closeModal={setCancelModalOpen}
        />
      </CancelModal>
      <AlertContainer>
        <Alert />
      </AlertContainer>
    </CgPlansContainer>
  );
};

export default CgSubscription;
